import { Injectable } from '@angular/core';
import { Resp } from '../interfaces/resp';
import { Observable, Subject } from 'rxjs';
import { ErrorService } from '../services/error.service';

import { AngularFireAuth } from '@angular/fire/auth';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private authState: any = null;

  constructor(
    private errorService: ErrorService,
    private afAuth: AngularFireAuth,
    private userService: UserService
  ) { }

  isAuth(): Observable<boolean> {
    let sub = new Subject<boolean>();

    this.afAuth.authState.pipe(
      // map(auth => {
      //   console.log('auth',auth);
      //   this.authState = auth
      //   return this.authState ? true : false;
      // })
    ).subscribe(auth => {
      this.authState = auth;
      if (this.authState) {
        this.userService.setSession(this.authState.uid).subscribe(r => {
          sub.next(r);
        });
      } else {
        sub.next(false);
      }
    });

    return sub;
  }


  register(email: string, password: string, newsletter:boolean): Observable<Resp> {

    let sub = new Subject<Resp>();
    let resp: Resp = {
      complete: false
    };

    this.afAuth.createUserWithEmailAndPassword(email, password)
      .then(r => {
        resp.data = {
          uid: r.user.uid,
          newsletter: newsletter
        }
        resp.complete = true;
      }).catch(r => {
        resp.message = this.errorService.convertMessage(r);
      }).finally(() => {
        sub.next(resp);
      });

    return sub;
  }

  login(email: string, password: string): Observable<Resp> {

    let sub = new Subject<Resp>();
    let resp: Resp = {
      complete: false
    };

    this.afAuth.signInWithEmailAndPassword(email, password)
      .then(r => {
        resp.complete = true;
      }).catch(r => {
        resp.message = this.errorService.convertMessage(r);
      }).finally(() => {
        sub.next(resp);
      });

    return sub;
  }

  logout(): Observable<Resp> {
    let sub = new Subject<Resp>();
    let resp: Resp = {
      complete: false
    };

    this.afAuth.signOut().then(r => {
      resp.complete = true;
    }).catch(r => {
      resp.message = this.errorService.convertMessage(r);
    }).finally(() => {
      sub.next(resp);
    });

    return sub;
  }

}
