import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Resp } from '../interfaces/resp';
import { AngularFirestore } from '@angular/fire/firestore';
import { ErrorService } from './error.service';
import { User } from '../interfaces/user';
import { environment } from 'src/environments/environment';

import * as moment from "moment";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private user: User;
  private url = `${environment.api}`;
  private httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded"
    })
  };

  constructor(
    private afDB: AngularFirestore,
    private errorService: ErrorService,
    private http: HttpClient
  ) { }

  setSession(user_uid: string, user?: User): Observable<boolean> {
    let sub = new Subject<boolean>();
    if (user_uid == null) {
      this.user = user;
      setTimeout(() => {
        sub.next(true);
      }, 100);
    } else {

      this.get(user_uid).subscribe(r => {
        if (r.complete) {
          this.user = r.data;
        }
        sub.next(r.complete);
      });

    }
    return sub;
  }

  getSession() {
    return { ...this.user };
  }


  get(user_uid: string): Observable<Resp> {

    let sub = new Subject<Resp>();
    let resp: Resp = {
      complete: false
    };

    this.afDB.doc(`users/${user_uid}`).get().subscribe(
      r => {
        resp.data = r.data();
        if (r.data()) {
          resp.complete = true;
        } else {
          resp.message = 'No data';
        }
      },
      err => {
        resp.message = this.errorService.convertMessage(err);
      },
      () => {
        sub.next(resp);
      });

    return sub;
  }

  /**
  * creamos al usuario en la base de datos
  * @param user es el usuario a crear
  * @returns un observable de tipo Resp
  */
  save(user: User): Observable<Resp> {

    let sub = new Subject<Resp>();
    let resp: Resp = {
      complete: false
    };

    // user.created = moment().format('x');
    user.created = moment().toDate();
    user.updated = moment().toDate();

    //crear usuario en la bd con el uid de la autentifiacion
    this.afDB.doc(`users/${user.uid}`)
      .set(user)
      .then(r => {
        resp.complete = true;
        resp.data = user;
        sub.next(resp);
      }).catch(r => {
        resp.message = this.errorService.convertMessage(r);
        sub.next(resp);
      })
      .finally(() => {
      });

    return sub;
  }

  update(user: User | any): Observable<Resp> {
    let sub = new Subject<Resp>();
    let resp: Resp = {
      complete: false
    };

    user.updated = moment().toDate();

    this.afDB.doc(`users/${user.uid}`).update(user)
      .then(r => {
        resp.complete = true;
        this.setSession(null, { ...this.user, ...user });
      })
      .catch(r => {
        resp.message = this.errorService.convertMessage(r);
      })
      .finally(() => {
        sub.next(resp);
      });

    return sub;
  }

  delete() {

  }

  sendMailToCompliance(user: any) {  //send message to compliance
    //console.log('user:sendMailToCompliance', user);
    this.http.post(`${this.url}/midpointSendMailToCompliance`, `op=register&user=${JSON.stringify(user)}&profile={}`, this.httpOptions).subscribe();
  }

}
