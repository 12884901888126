import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: '/tabs/home', pathMatch: 'full' },
  { path: 'home', loadChildren: './pages/home/home.module#HomePageModule' },
  { path: 'dac', loadChildren: './pages/dac/dac.module#DacPageModule' },
  { path: 'tabs', loadChildren: './pages/tabs/tabs.module#TabsPageModule', canLoad: [AuthGuard] },
  { path: 'auth', loadChildren: './auth/auth.module#AuthPageModule' },
  { path: 'new-profile', loadChildren: './new-profile/new-profile.module#NewProfilePageModule', canLoad: [AuthGuard] },
  { path: '**', redirectTo: '/tabs/home', pathMatch: 'full' },
  { path: 'history', loadChildren: './pages/operation/list/history/history.module#HistoryPageModule' },
  { path: 'process', loadChildren: './pages/operation/list/process/process.module#ProcessPageModule' },

];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
